export default {
  type: "templates-invoices",
  title: null,
  contact_details: "",
  other_infos: "",
  address: "",
  logo: null,
  background_color: null,
  header_color: null,
  background_color: null,
  section_color: null,
  is_default: false,
  relationshipNames: ["organization"],
  organization: {
    type: "organizations",
    id: null,
  },
};
