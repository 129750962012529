<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left">
          <div class="kw-back">
            <router-link
              v-if="$currentUserCan($permissions.PERM_VIEW_APP_ADMIN)"
              :to="{ name: 'Admin Page' }"
              v-slot="{ href, navigate }"
              custom
            >
              <a :href="href" @click="navigate">
                <i class="far fa-long-arrow-left"></i>
              </a>
            </router-link>
            <h3>
              {{ $t("TEMPLATES_INVOICES.TEMPLATES_INVOICES_LIST") }}
            </h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("APPS.ADMIN_MODULE") }}</span>
              </li>
              <!--<li>
                <span>{{ $t("TEMPLATES_INVOICES.COMMUNICATION") }}</span>
              </li>-->
              <li>
                <span>{{
                  $t("TEMPLATES_INVOICES.TEMPLATES_INVOICES_LIST")
                }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="globalOrganization?.id" class="page-wrapper-header-right">
          <base-button
            class="elite-button add"
            icon
            size="sm"
            @click="openTemplateInvoiceCreateModal"
            v-if="$currentUserCan($permissions.PERM_CREATE_TEMPLATES_INVOICES)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-plus"></i>
            </span>
            <span class="btn-inner--text">{{
              $t("TEMPLATES_INVOICES.ADD_TEMPLATE_INVOICE")
            }}</span>
          </base-button>
        </div>
      </div>
      <div v-if="globalOrganization?.id">
        <template-invoice-list-table
          @onViewTemplateInvoice="openTemplateInvoiceViewModal"
          @onEditTemplateInvoice="openTemplateInvoiceEditModal"
          @onDeleteTemplateInvoice="deleteTemplateInvoice"
          :key="renderKey * 100"
        />
        <div
          v-if="isViewTemplateInvoiceModalOpened"
          class="resizable-wrapper"
          v-bind:class="[isViewTemplateInvoiceModalOpened ? 'show' : 'hide']"
          :key="renderKey * 200"
        >
          <div
            class="resizable-wrapper-content"
            v-resizable-modal="'TEMPLATE_INVOICE_VIEW'"
          >
            <div class="resizable-wrapper-content-header">
              <div class="resizable-wrapper-content-header-left">
                <button class="close" @click="closeTemplateInvoiceModal">
                  <i class="far fa-arrow-left"></i>
                </button>
                <h1>
                  {{ $t("TEMPLATES_INVOICES.TEMPLATE_DETAIL") }}
                </h1>
              </div>
              <div class="resizable-wrapper-content-header-right">
                <wrapper-expand-button></wrapper-expand-button>
              </div>
            </div>
            <div class="resizable-wrapper-content-body">
              <view-template-invoice-page
                v-if="openTemplateInvoice"
                :templateInvoiceId="openTemplateInvoice.id"
                @onEditTemplateInvoice="openTemplateInvoiceEditModal"
                @onDeleteTemplateInvoice="deleteTemplateInvoice"
              />
            </div>
          </div>
        </div>

        <div
          v-if="isEditTemplateInvoiceModalOpened"
          class="resizable-wrapper"
          v-bind:class="[isEditTemplateInvoiceModalOpened ? 'show' : 'hide']"
          :key="renderKey * 300"
        >
          <div
            class="resizable-wrapper-content"
            v-resizable-modal="'TEMPLATE_INVOICE'"
          >
            <div class="resizable-wrapper-content-header">
              <div class="resizable-wrapper-content-header-left">
                <button class="close" @click="closeTemplateInvoiceModal">
                  <i class="far fa-arrow-left"></i>
                </button>
                <h1>
                  {{ $t("TEMPLATES_INVOICES.EDIT_TEMPLATE_INVOICE") }}
                </h1>
              </div>
              <div class="resizable-wrapper-content-header-right">
                <wrapper-expand-button></wrapper-expand-button>
              </div>
            </div>
            <div class="resizable-wrapper-content-body">
              <edit-template-invoice-page
                v-if="openTemplateInvoice"
                :templateInvoiceId="openTemplateInvoice.id"
                @onViewTemplateInvoice="openTemplateInvoiceViewModal"
                @oncloseTemplateInvoiceModal="closeTemplateInvoiceModal"
              />
            </div>
          </div>
        </div>

        <div
          v-if="isAddTemplateInvoiceModalOpened"
          class="resizable-wrapper"
          v-bind:class="[isAddTemplateInvoiceModalOpened ? 'show' : 'hide']"
          :key="renderKey * 400"
        >
          <div
            class="resizable-wrapper-content"
            v-resizable-modal="'TEMPLATE_INVOICE'"
          >
            <div class="resizable-wrapper-content-header">
              <div class="resizable-wrapper-content-header-left">
                <button class="close" @click="closeTemplateInvoiceModal">
                  <i class="far fa-arrow-left"></i>
                </button>
                <h1>
                  {{ $t("TEMPLATES_INVOICES.ADD_TEMPLATE_INVOICE") }}
                </h1>
              </div>
              <div class="resizable-wrapper-content-header-right">
                <wrapper-expand-button></wrapper-expand-button>
              </div>
            </div>
            <div class="resizable-wrapper-content-body">
              <add-template-invoice-page
                @onCloseTemplateInvoiceModal="closeTemplateInvoiceModal"
                @onViewTemplateInvoice="openTemplateInvoiceViewModal"
              />
            </div>
          </div>
        </div>
      </div>
      <select-an-organization-message v-if="!globalOrganization?.id" />
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import TemplateInvoiceListTable from "./partials/TemplateInvoiceListTable.vue";
import EditTemplateInvoicePage from "./components/EditTemplateInvoiceComponent.vue";
import AddTemplateInvoicePage from "./components/AddTemplateInvoiceComponent.vue";
import ViewTemplateInvoicePage from "./components/ViewTemplateInvoiceComponent.vue";
import SelectAnOrganizationMessage from "@/components/SelectAnOrganizationMessage.vue";
import { mapGetters } from "vuex";
import WrapperExpandButton from "@/components/WrapperExpandButton";

export default {
  layout: "DashboardLayout",

  components: {
    SelectAnOrganizationMessage,
    TemplateInvoiceListTable,
    NotificationSubscription,
    EditTemplateInvoicePage,
    AddTemplateInvoicePage,
    ViewTemplateInvoicePage,
    [Button.name]: Button,
    WrapperExpandButton,
  },

  mixins: [requestErrorMixin],

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
    ...mapGetters("organizationOptions", {
      organizationConfig: "organizationConfig",
    }),
  },

  watch: {},

  data() {
    const templateInvoiceId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewTemplateInvoiceModalOpened = false;
    let isEditTemplateInvoiceModalOpened = false;
    let isAddTemplateInvoiceModalOpened = false;
    let openTemplateInvoice = null;
    if (templateInvoiceId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewTemplateInvoiceModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditTemplateInvoiceModalOpened = true;
      }
      openTemplateInvoice = { id: templateInvoiceId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddTemplateInvoiceModalOpened = true;
    }
    return {
      isViewTemplateInvoiceModalOpened: isViewTemplateInvoiceModalOpened,
      isEditTemplateInvoiceModalOpened: isEditTemplateInvoiceModalOpened,
      isAddTemplateInvoiceModalOpened: isAddTemplateInvoiceModalOpened,
      openTemplateInvoice: openTemplateInvoice,
      renderKey: 1,
    };
  },

  methods: {
    openTemplateInvoiceCreateModal() {
      this.closeTemplateInvoiceModal();
      this.isAddTemplateInvoiceModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List TemplatesInvoices",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openTemplateInvoiceViewModal(templateInvoice, reRender = false) {
      this.closeTemplateInvoiceModal();
      this.openTemplateInvoice = templateInvoice;
      this.isViewTemplateInvoiceModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List TemplatesInvoices",
          query: {
            id: this.openTemplateInvoice.id,
            action: QUERY_ACTIONS_VIEW,
          },
        }).href
      );
    },
    openTemplateInvoiceEditModal(templateInvoice) {
      this.closeTemplateInvoiceModal();
      this.openTemplateInvoice = templateInvoice;
      this.isEditTemplateInvoiceModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List TemplatesInvoices",
          query: {
            id: this.openTemplateInvoice.id,
            action: QUERY_ACTIONS_EDIT,
          },
        }).href
      );
    },
    closeTemplateInvoiceModal() {
      this.isAddTemplateInvoiceModalOpened = false;
      this.isViewTemplateInvoiceModalOpened = false;
      this.isEditTemplateInvoiceModalOpened = false;
      this.openTemplateInvoice = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List TemplatesInvoices",
          query: {},
        }).href
      );
    },

    async deleteTemplateInvoice(templateInvoice) {
      const confirmation = await swal.fire({
        text: this.$t("TEMPLATES_INVOICES.DELETE_THIS_TEMPLATE_INVOICE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "templatesInvoices/destroy",
            templateInvoice.id
          );
          this.renderKey++;
          this.closeTemplateInvoiceModal();
          this.$notify({
            type: "success",
            message: this.$t("TEMPLATES_INVOICES.TEMPLATE_INVOICE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    onCloseTemplateInvoiceModal() {
      this.$emit("onCloseTemplateInvoiceModal");
    },
  },
};
</script>
