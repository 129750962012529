<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!templateInvoice">
      <span class="loader"></span>
    </span>
    <template-invoice-form
      v-if="templateInvoice"
      :loading="loading"
      :templateInvoiceData="templateInvoice"
      :formErrors="formErrors"
      @templateInvoiceSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onCloseTemplateInvoiceModal="onCloseTemplateInvoiceModal"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import TemplateInvoiceForm from "../partials/TemplateInvoiceForm.vue";

export default {
  layout: "DashboardLayout",

  components: { TemplateInvoiceForm },

  mixins: [alertLeave],

  props: {
    templateInvoiceId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      templateInvoice: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("templatesInvoices/get", this.templateInvoiceId);
        this.templateInvoice = this.$store.getters["templatesInvoices/templatesInvoice"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async handleSubmit(templateInvoice) {
      this.loading = true;
      let templateInvoiceData = cloneDeep(templateInvoice);

      try {
        await this.$store.dispatch("templatesInvoices/update", templateInvoiceData);
        this.$notify({
          type: "success",
          message: this.$t("TEMPLATES_INVOICES.TEMPLATE_INVOICE_UPDATED"),
        });
        const templateInvoice =
          this.$store.getters["templatesInvoices/templatesInvoice"];
        this.$emit("onViewTemplateInvoice", templateInvoice, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    onCloseTemplateInvoiceModal() {
      this.$emit("onCloseTemplateInvoiceModal");
    },
  },
};
</script>
