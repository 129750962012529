<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">{{ templateInvoice.title }}</h3>
    <div class="all-infos">
      <div class="all-infos-text">
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="templateInvoice.organization" />
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("TEMPLATES_INVOICES.TITLE") }}</dt>
          <dd>
            <div v-html="templateInvoice.title"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="templateInvoice.created_at">
            {{ $formatDate(templateInvoice.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="templateInvoice.updated_at">
            {{ $formatDate(templateInvoice.updated_at) }}
          </dd>
        </dl>
        <dl class="row full">
          <dt>{{ $t("TEMPLATES_INVOICES.IS_DEFAULT_TEMPLATE") }}</dt>
          <dd>
            {{templateInvoice.is_default ? $t("COMMON.YES") : $t("COMMON.NO")}}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import Contact from "@/components/Contact.vue";
export default {
  name: "template-invoice-view-global",

  components: {},

  props: ["templateInvoice"],

  data() {
    return {};
  },

  computed: {},

  created() {
    console.log(this.templateInvoice);
  },

  methods: {},

  mounted() {},

  watch: {
    templateInvoice(templateInvoice) {},
  },
};
</script>
<style scoped>
</style>
