<template>
  <div class="page-wrapper-table">
    <div class="page-wrapper-table-header">
      <div class="page-wrapper-table-header-left">
        <base-input
          v-model="query"
          type="search"
          class="search"
          prepend-icon="far fa-search"
          :placeholder="$t('COMMON.SEARCH')"
          clearable
        />
      </div>
      <div class="page-wrapper-table-header-right">
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('COMMON.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="page-wrapper-table-body">
      <div class="page-wrapper-table-body-inner">
        <el-table
          class="align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="templateInvoices"
          @sort-change="sortChange"
        >
          <div class="table-loading" slot="empty" v-if="loading">
            <img src="/img/loading.gif" />
          </div>
          <el-table-column
            :label="$t('TEMPLATES_INVOICES.TITLE')"
            min-width="220"
            sortable="custom"
          >
            <template v-slot="{ row }">
              {{ row.title }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('TEMPLATES_INVOICES.IS_DEFAULT_TEMPLATE')"
            min-width="220"
            sortable="custom"
          >
            <template v-slot="{ row }">
              {{ row.is_default ? $t('COMMON.YES') : $t('COMMON.NO')  }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('COMMON.CREATED_AT')"
            prop="created_at"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ $formatDate(row.created_at) }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" min-width="120">
            <div slot-scope="{ row }" class="table-actions">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  <i class="far fa-ellipsis-v"></i>
                </span>
                <el-dropdown-menu slot="dropdown" class="actions-dropdown">
                  <el-dropdown-item
                    v-if="
                      $currentUserCan($permissions.PERM_VIEW_TEMPLATES_INVOICES)
                    "
                  >
                    <a
                      type="text"
                      @click="viewTemplateInvoice(row)"
                      class="table-action view"
                      data-toggle="tooltip"
                    >
                      <span class="icon">
                        <img src="/img/kw-eye.svg" alt="icon" />
                      </span>
                      <span class="text">
                        {{ $t("COMMON.VIEW_DETAILS") }}
                      </span>
                    </a>
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="
                      $currentUserCan($permissions.PERM_EDIT_TEMPLATES_INVOICES)
                    "
                  >
                    <a
                      type="text"
                      @click="editTemplateInvoice(row)"
                      class="table-action edit"
                      data-toggle="tooltip"
                    >
                      <span class="icon">
                        <img src="/img/kw-edit.svg" alt="icon" />
                      </span>
                      <span class="text">
                        {{ $t("COMMON.EDIT") }}
                      </span>
                    </a>
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="
                      $currentUserCan($permissions.PERM_DELETE_TEMPLATES_INVOICES)
                    "
                  >
                    <a
                      type="text"
                      @click="deleteTemplateInvoice(row)"
                      class="table-action delete"
                      data-toggle="tooltip"
                    >
                      <span class="icon">
                        <img src="/img/kw-delete.svg" alt="icon" />
                      </span>
                      <span class="text">
                        {{ $t("COMMON.DELETE") }}
                      </span>
                    </a>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="page-wrapper-table-footer">
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}

        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";

export default {
  name: "template-invoice-list-table",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "organization id",
    },
  },

  data() {
    return {
      query: null,
      tracking_number: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      title: null,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      templateInvoices: [],
      loading: true,
      dateRange: null,
      selectedOrganization: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    dateRange: {
      handler: "getListDebounced",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }

        await this.$store.dispatch("templatesInvoices/list", params);
        this.templateInvoices = this.$store.getters["templatesInvoices/list"];
        this.total = this.$store.getters["templatesInvoices/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    deleteTemplateInvoice(templateInvoice) {
      this.$emit("onDeleteTemplateInvoice", templateInvoice);
    },

    viewTemplateInvoice(templateInvoice) {
      this.$emit("onViewTemplateInvoice", templateInvoice);
    },

    editTemplateInvoice(templateInvoice) {
      this.$emit("onEditTemplateInvoice", templateInvoice);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
