<template>
  <div class="container-fluid">
    <template-invoice-form
      :loading="loading"
      :templateInvoiceData="templateInvoice"
      :formErrors="formErrors"
      @templateInvoiceSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onCloseTemplateInvoiceModal="onCloseTemplateInvoiceModal"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultTemplateInvoice from "../defaultTemplateInvoice.js";
import TemplateInvoiceForm from "../partials/TemplateInvoiceForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    TemplateInvoiceForm,
  },

  mixins: [alertLeave],

  data() {
    const templateInvoice = cloneDeep(defaultTemplateInvoice);
    const me = this.$store.getters["profile/me"];
    return {
      templateInvoice: templateInvoice,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(templateInvoice) {
      this.loading = true;

      const templateInvoiceData = cloneDeep(templateInvoice);
      delete templateInvoiceData.id;
      console.log("template invoice data : ", templateInvoiceData);
      try {
        await this.$store.dispatch(
          "templatesInvoices/add",
          templateInvoiceData
        );
        this.$notify({
          type: "success",
          message: this.$t("TEMPLATES_INVOICES.TEMPLATE_INVOICE_ADDED"),
        });
        const templateInvoice =
          this.$store.getters["templatesInvoices/templatesInvoice"];
        this.$emit("onViewTemplateInvoice", templateInvoice, true);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
    onCloseTemplateInvoiceModal() {
      this.$emit("onCloseTemplateInvoiceModal");
    },
  },
};
</script>
